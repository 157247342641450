import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { setLoading } from '../states/actions';
import { useUserContext } from '../context/UserContextProvider';
import { ButtonContainer, CustomTextInput } from '../utils/GlobalStyles';
import axios from 'axios';
import { BASE_URL } from '../config';

const Heading = styled.h3`
    padding: 2% 0;
`;

const ForgotPassword = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    &:hover {
        color: #646464;
    }
`;

const LoginButton = styled.button`
    background: #5ea73c;
    padding: 12px 8px;
    color: white;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #5ea73c;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
`;

const LoginPage = (props) => {
    const { loginUser } = useUserContext();

    const {
        user_email,
        setEmail,
        user_password,
        setPassword,
        hasAccount,
        setHasAccount,
        clearInputs,
    } = props;

    let history = useNavigate();

    const handleLogin = (email, password) => {
        loginUser(email, password)
            .then(() => {
                console.log('Logged In');

                axios({
                    method: 'GET',
                    url: BASE_URL + '/login',
                }).then((res) => {
                    console.log(res);
                    if (res.data.account_setup === false) {
                        console.log('Logged in');
                        axios({
                            method: 'PATCH',
                            url: BASE_URL + '/login',
                            data: {
                                account_setup: true,
                            },
                        })
                            .then(() => {
                                history('/first-login', { push: true });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        history('/home', { push: true });
                    }
                });
            })
            .catch((err) => {
                switch (err.code) {
                    case 'auth/invalid-email':
                        toast.error('Invalid Email');
                        break;
                    case 'auth/user-disabled':
                        toast.error("User's account has been disabled");
                        break;
                    case 'auth/user-not-found':
                        toast.error("User doesn't exist");
                        break;
                    case 'auth/wrong-password':
                        toast.error("Wrong password");
                        break;
                    default:
                        break;
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Heading>Login Now</Heading>

            <CustomTextInput
                fullWidth
                label="Enter email"
                type="email"
                required
                value={user_email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <CustomTextInput
                fullWidth
                type="password"
                label="Enter password"
                required
                value={user_password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <ForgotPassword
                    onClick={() => {
                        setHasAccount(!hasAccount);
                        clearInputs();
                    }}
                >
                    Forget Password?
                </ForgotPassword>
            </div>

            <ButtonContainer>
                <LoginButton
                    onClick={() => {
                        handleLogin(user_email, user_password);
                    }}
                >
                    Login
                </LoginButton>
            </ButtonContainer>

        </>
    );
};

export default LoginPage;
