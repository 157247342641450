import React from 'react';
import styled from 'styled-components';
import ECT_VISION from '../../static/images/logo.svg';

import { SideNavData } from './SideNavData';
import { NavLink, useNavigate } from 'react-router-dom';
import { dummy } from '../../screens/Profile';

const SideNavWrapper = styled.div`
    grid-area: side-nav;
    background-color: #fafafa;
    display: grid;
    height: 100vh;
    overflow-y: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 15% 70% 15%;
    grid-template-areas: 'left_header';
    border-right: 1px solid #e5e5e5;
`;

const LogoWrapper = styled.div`
    display: grid;
    align-content: center;
    justify-content: center;
`;

const NavMenu = styled.nav`
    flex: 0;
    text-align: start;
    transition: 0.1s;
`;

const SideNavUl = styled.ul`
    padding: 0;
`;

const NavList = styled(NavLink)`
    list-style: none;
    text-decoration: none;
    color: black;
    padding: 16px 0;
    display: inline-flex;
    font-weight: 500;
    margin: 0 0;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    align-content: center;
    align-items: center;
    width: 100%;
    transition: 0.1s;

    &:hover {
        //color: #5ea73c;
        background: #f4f4f4;
        cursor: pointer;
        font-weight: 600;
        transition: 0.1s;
    }
`;

const Icon = styled.span`
    margin-right: 20px;
    margin-left: 20px;
`;

const MenuTitle = styled.span`
    margin: 0;
`;

const MenuHeader = styled.p`
    font-family: 'Oswald', sans-serif;
    margin-left: 20px;
    color: #99a095;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 1em;
`;

const ProfileWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'profile_icon profile_name';
    justify-content: left;
    align-content: center;
    border-top: 1px solid #e5e5e5;
    font-family: 'Oswald', sans-serif;
`;

const ImageWrapper = styled.div`
    width: 100%;
    //padding: 4px;
    position: relative;
    display: grid;
    grid-area: profile_icon;
    justify-content: end;
`;

const ImgContent = styled.div`
    width: max-content;
    padding: 4px;

    & > img {
        border-radius: 50px;
    }
`;

const ProfileTitleWrapper = styled.div`
    margin: 2% 0 0 10%;
    cursor: pointer;
`;

const ProfileTitle = styled.h3`
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #4b4b4b;
    grid-area: profile_name;
`;

const ProfileSubtitle = styled.p`
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    color: #7d7d7d;
    padding: 0;
    margin: 0;
    grid-area: profile_subname;

    &:hover {
        color: #4b4b4b;
    }
`;

function SideNav() {
    let navigate = useNavigate();

    return (
        <SideNavWrapper>
            <LogoWrapper>
                <img width={150} src={ECT_VISION} alt="logo" />
            </LogoWrapper>

            <NavMenu>
                <MenuHeader>MENU</MenuHeader>
                <SideNavUl>
                    {SideNavData.map((value, index) => {
                        return (
                            <NavList
                                key={index}
                                id={
                                    window.location.pathname.includes(value.link[0]) ||
                                    window.location.pathname.includes(value.link[1])
                                        ? 'active'
                                        : ''
                                }
                                to={value.link[0]}
                            >
                                <Icon>{value.icon}</Icon>
                                <MenuTitle>{value.title}</MenuTitle>
                            </NavList>
                        );
                    })}
                </SideNavUl>
            </NavMenu>

            <ProfileWrapper>
                <ImageWrapper>
                    <ImgContent>
                        <img width={48} src={dummy} alt="logo" />
                    </ImgContent>
                </ImageWrapper>

                <ProfileTitleWrapper onClick={() => navigate('/profile')}>
                    <ProfileTitle>JOEL GELLER</ProfileTitle>
                    <ProfileSubtitle>
                        My Profile <strong>&gt;</strong>
                    </ProfileSubtitle>
                </ProfileTitleWrapper>
            </ProfileWrapper>
        </SideNavWrapper>
    );
}

export default SideNav;
