import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';

export const WorkersWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

export const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

export const EditIcon = styled(BsThreeDots)`
    font-size: 20px;
    border-radius: 8px;
    padding: 5px;
    display: none;
    transition: all ease 200ms;
`;

export const WorkerProfileWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: auto 2fr auto;
    grid-template-rows: auto;
    font-family: 'Poppins', sans-serif;
`;

export const ProfileTitleWrapper = styled.div`
    //cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
`;

export const ImageWrapper = styled.div`
    width: max-content;
    position: relative;
    display: grid;
`;

export const ImgContent = styled.div`
    width: max-content;
    display: grid;
    padding: 10px;

    & > img {
        border-radius: 50px;
    }
`;

export const ProfileTitle = styled.h4`
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #4b4b4b;
`;

export const ProfileSubtitle = styled.p`
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    color: #7d7d7d;
    padding: 0;
    margin: 0;
    grid-area: profile_subname;

    &:hover {
        color: #4b4b4b;
    }
`;

export const WorkersContent = styled.div`
    grid-area: acc-content;
    position: relative;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        position: sticky;
        top: 0;
        color: rgba(55, 70, 48, 0.5);
        height: 2.5em;
        z-index: 1;
        font-family: 'Oswald', sans-serif;
        letter-spacing: 1px;
        font-weight: 600;

        background-color: #fff;
    }

    tr {
        text-align: left;

        &:hover {
            background-color: rgba(246, 246, 246, 0.5);
        }

        &:hover ${EditIcon} {
            display: block;
            background-color: rgba(234, 234, 234, 0.5);
            cursor: pointer;
        }
    }

    .table-header {
        border-bottom: 2px solid #e9eced;
    }

    tr:not(.table-header) {
        height: 5rem;
        margin: auto 0;
    }

    .mid-title {
        text-align: center;
    }

    .mid-title:nth-child(3) {
        border-left: 1px solid #e9eced;
        border-right: 1px solid #e9eced;
    }

    td:nth-child(1) {
        padding: 0 0 0 4rem;
        margin: 0;
        display: inline-flex;
        align-items: center;
        width: 90%;
    }

    th:nth-child(1) {
        padding: 0 0 0 4rem;
        margin: 0;
    }
`;
