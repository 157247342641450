import React from 'react';
import './modal.css';
import styled from 'styled-components';
import { dummy } from '../../screens/Profile';

const ModalBodyWrapper = styled.div`
    //height: 95%;
    //padding: 5%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 2fr 0.5fr;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;
const SubText = styled.p`
    margin: 0;
`;

const ImageWrapper = styled.div`
    width: max-content;
    position: relative;
    display: grid;
`;

const ImgContent = styled.div`
    width: max-content;
    padding: 10px;

    & > img {
        border-radius: 50px;
    }
`;

const ProfileTitleWrapper = styled.div`
    //cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
`;

const ProfileTitle = styled.h4`
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #4b4b4b;
`;

const ProfileSubtitle = styled.p`
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    color: #7d7d7d;
    padding: 0;
    margin: 0;
    grid-area: profile_subname;

    &:hover {
        color: #4b4b4b;
    }
`;

const ModalBodyContent = styled.div`
    display: inline-flex;
    width: 100%;
`;
const ModalFooter = styled.div`
    padding: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
`;

const CloseButton = styled.button`
    border: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    background: none;
    width: 120px;
    height: max-content;
    display: grid;
    justify-content: center;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        background-color: rgba(227, 227, 227, 0.33);
        border-radius: 6px;
    }
`;

const WorkersNotifiedModal = (props) => {
    return (
        <ModalBodyWrapper>
            <div className="modal-header">
                <Heading>{props.title}</Heading>
                <SubText>{props.subText}</SubText>
            </div>
            <div className="modal-body">
                <ModalBodyContent style={{ display: 'inline-flex' }}>
                    <ImageWrapper>
                        <ImgContent>
                            <img width={45} src={dummy} alt="logo" />
                        </ImgContent>
                    </ImageWrapper>

                    <ProfileTitleWrapper>
                        <div>
                            <ProfileTitle>{'Bivav Raj Satyal'}</ProfileTitle>
                            <ProfileSubtitle>{'bivavraj@gmail.com'}</ProfileSubtitle>
                        </div>
                    </ProfileTitleWrapper>
                </ModalBodyContent>
                <ModalBodyContent style={{ display: 'inline-flex' }}>
                    <ImageWrapper>
                        <ImgContent>
                            <img width={45} src={dummy} alt="logo" />
                        </ImgContent>
                    </ImageWrapper>

                    <ProfileTitleWrapper>
                        <div>
                            <ProfileTitle>{'Arshdeep Singh'}</ProfileTitle>
                            <ProfileSubtitle>{'arsh@gmail.com'}</ProfileSubtitle>
                        </div>
                    </ProfileTitleWrapper>
                </ModalBodyContent>
            </div>

            {/*<ModalFooter>*/}
            {/*    <CloseButton onClick={props.onClose}>Close</CloseButton>*/}
            {/*</ModalFooter>*/}
        </ModalBodyWrapper>
    );
};

export default WorkersNotifiedModal;
