import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import '../modal/modal.css';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter } from '../../utils/GlobalStyles';
import AddMarkerOnMap from './AddMarkerOnMap';

const ModalBodyWrapper = styled.div`
    //height: 80%;
    //padding: 5%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const ModalBodyItem = styled.div`
    //width: 100%;
`;
const ModalFooter = styled.div`
    padding: 10px;
    display: grid;
    justify-content: right;
    align-items: center;
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const Modal = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
`;

const ModalBody = styled.div`
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
`;

const ModalHeader = styled.div`
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
`;

const SearchInput = styled.input`
    background-color: #f4f4f4;
    border: 1px solid #e5e5e5;
    color: #374630;
    border-radius: 5px;
    padding: 5px 10px;
    width: 200px;
    transition: 0.2s ease all;
    font-family: 'Poppins', sans-serif;
`;

const MapSection = styled.div``;

const LocButtonContainer = styled(ButtonContainer)`
    width: 300px;
    padding: 2% 0;
`;

export const AddFieldModal = (props) => {
    const { show, onClose, title, location, fieldData, setFieldData } = props;

    return (
        <ModalBodyWrapper>
            <ModalHeader>
                <Heading>{title}</Heading>
                <SearchInput placeholder={'Search city...'} />
            </ModalHeader>
            <ModalBody>
                <ModalBodyItem>
                    <MapSection>
                        {/*<Map location={props.location} zoomLevel={12} />*/}
                        <AddMarkerOnMap
                            location={location}
                            zoomLevel={12}
                            fieldData={fieldData}
                            setFieldData={setFieldData}
                        />
                    </MapSection>
                </ModalBodyItem>
            </ModalBody>

            <ModalFooter>
                <LocButtonContainer>
                    <ButtonFooter
                        onClick={() => {
                            setFieldData({
                                ...fieldData,
                                location: {
                                    address: '',
                                    lat: 39.205907,
                                    lng: -94.290529,
                                },
                            });
                            onClose();
                        }}
                    >
                        Cancel
                    </ButtonFooter>
                    <ChangeDetailsBtn
                        onClick={(e) => {
                            e.preventDefault();
                            console.log(fieldData);
                            onClose();
                        }}
                    >
                        DONE
                    </ChangeDetailsBtn>
                </LocButtonContainer>
            </ModalFooter>
        </ModalBodyWrapper>
    );
};
