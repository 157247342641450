import React, { useState } from "react";

import styled from "styled-components";
import HeaderLeft from "../components/HeaderLeft";
import SideNav from "../components/sidenav/SideNav";
import HeaderRight from "../components/HeaderRight";
import { ButtonContainer, ButtonFooter, CustomNotifyInput, CustomTextInput } from "../utils/GlobalStyles";
import { IconButton, InputAdornment } from "@mui/material";

import { ReactComponent as Subtract } from "../static/icons/subtract.svg";
import { ReactComponent as Add } from "../static/icons/add.svg";

const AccountSettingsWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`

const AccContent = styled.div`
    grid-area: acc-content;
`

const AccContentBox = styled.div`
    width: 350px;
    margin: 4em;
    display: grid;
    justify-content: center;
`

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`

const SubtractButton = styled(Subtract)`
    &:hover > rect {
        fill: #eaeaea;
    }
`

const AddButton = styled(Add)`
    &:hover > rect {
        fill: #eaeaea;
    }
`

const NotifyCount = styled.p`
    font-weight: 600;
    color: black;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
`

export default function AccountSettings() {

    const [notifyCounter, setNotifyCounter] = useState(0)

    function handleAdd() {
        setNotifyCounter((prevState) => prevState + 1)
    }
    function handleSubtract() {
        if (notifyCounter > 0) {
            setNotifyCounter((prevState) => prevState - 1)
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <AccountSettingsWrapper>
            <SideNav />
            <AccContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={'Smith Farms'}
                        subTitle={'35 Workers & 120 Fields'}
                    />
                    <HeaderRight text={'delete'} />
                </HeaderWrapper>

                <AccContentBox>
                    <CustomTextInput
                        label="Account Name"
                        type="text"
                        // value={account_name}
                        // onChange={(e) => setAccountName(e.target.value)}
                    />
                    <CustomTextInput
                        label="Email"
                        type="email"
                        // value={user_data["email"]}
                    />

                    <CustomNotifyInput
                        label="Notify members"
                        id="outlined-adornment"
                        disabled
                        value={'days before due date'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <NotifyCount>
                                        {notifyCounter.toLocaleString('en-US', {
                                            minimumIntegerDigits: 2,
                                            useGrouping: false,
                                        })}
                                    </NotifyCount>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        disableTouchRipple={true}
                                        aria-label="toggle password visibility"
                                        onClick={() => handleSubtract()}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <SubtractButton />
                                    </IconButton>
                                    <IconButton
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        disableTouchRipple={true}
                                        aria-label="toggle password visibility"
                                        onClick={() => handleAdd()}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        <AddButton />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />

                    <ButtonContainer>
                        <ButtonFooter
                            onClick={() => {
                                // setIsClicked(false)
                            }}
                        >
                            Reset
                        </ButtonFooter>
                        <ChangeDetailsBtn
                        // onClick={() => changeDetails()}
                        >
                            Save
                        </ChangeDetailsBtn>
                    </ButtonContainer>
                </AccContentBox>
            </AccContent>
        </AccountSettingsWrapper>
    )
}
