import React, { useState } from 'react';
import Map from '../Map';
import EventsProgressBar from '../EventsProgressBar';
import WorkersNotifiedModal from '../modal/WorkersNotifiedModal';
import AllEvents from '../AllEvents';
import styled from 'styled-components';
import { ResetButton } from '../home_page/styled';
import ModalComponent from '../modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import { ModalCloseButton, ModalFooter } from '../../utils/GlobalStyles';

const MapSection = styled.div``;

const AllEventSection = styled.section`
    height: 48vh;
    background: white;
    overflow-y: scroll;
`;

const EventHeader = styled.div`
    display: inline-flex;
    width: 100%;
    height: 6vh;
    background: white;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #e5e5e5;
`;

const EventHeading = styled.h1`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Oswald', sans-serif;
    color: #9ca499;
    margin: 0;
    padding: 1em;
`;

const SubHeading = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    color: #999;
`;

const EventSubHeading = styled(SubHeading)`
    font-size: 12px;
    margin: 0;
    padding: 1em;
    color: #9ca499;
`;

export default function HayVisionEvents(props) {
    const {
        showModal,
        setShowModal,
        location,
        completeCount,
        setCompleteCount,
        dateComparison,
        setLocation,
        setZoomLevel,
        setMapCenter,
        mapCenter,
        zoomLevel,
        hayEvents,
    } = props;

    const [modalNotifyWorker, setModalNotifyWorker] = useState(false);
    const closeModalNotify = () => setModalNotifyWorker(false);
    const openModalNotify = () => setModalNotifyWorker(true);

    return (
        <>
            <MapSection>
                <Map
                    location={location}
                    setMapCenter={setMapCenter}
                    mapCenter={mapCenter}
                    zoomLevel={zoomLevel}
                    setZoomLevel={setZoomLevel}
                    mapLocations={hayEvents}
                />
            </MapSection>

            <EventsProgressBar
                allEventsCount={hayEvents.length}
                completeCount={completeCount}
            />

            <EventHeader>
                <EventHeading>HAY VISION EVENTS</EventHeading>
                <EventSubHeading>
                    {zoomLevel !== 12 && zoomLevel !== 11 ? (
                        <ResetButton
                            onClick={() => {
                                setZoomLevel(11);
                            }}
                        >
                            RESET
                        </ResetButton>
                    ) : null}

                    <span>
                        {completeCount}/{hayEvents.length}
                    </span>
                </EventSubHeading>
            </EventHeader>

            <AllEventSection>
                <AnimatePresence
                    // Disable any initial animation on children for first render
                    initial={false}
                    exitBeforeEnter={true}
                >
                    {modalNotifyWorker && (
                        <ModalComponent
                            key={'modal-worker'}
                            open={openModalNotify}
                            handleClose={closeModalNotify}
                            minWidth={'35%'}
                            width={'400px'}
                            minHeight={'400px'}
                            footer={
                                <ModalFooter>
                                    <ModalCloseButton onClick={closeModalNotify}>
                                        Close
                                    </ModalCloseButton>
                                </ModalFooter>
                            }
                        >
                            <WorkersNotifiedModal
                                title="Workers Notified"
                                subText={
                                    'This is the list of workers who were notified about this event.'
                                }
                                onClose={() => setShowModal(false)}
                                show={showModal}
                            />
                        </ModalComponent>
                    )}
                </AnimatePresence>

                {hayEvents
                    .sort(dateComparison)
                    .map((value, index) => {
                        return (
                            <AllEvents
                                key={index}
                                value={value}
                                completeCount={completeCount}
                                modalNotifyWorker={modalNotifyWorker}
                                open={openModalNotify}
                                handleClose={closeModalNotify}
                                setCompleteCount={setCompleteCount}
                                setLocation={setLocation}
                                location={location}
                                setMapCenter={setMapCenter}
                                mapCenter={mapCenter}
                                setZoomLevel={setZoomLevel}
                            />
                        );
                    })}
            </AllEventSection>
        </>
    );
}
