import styled from 'styled-components';
import Switch from '@mui/material/Switch';

const blue = {
    500: '#52A1FF',
};

const grey = {
    500: '#F4F4F4',
};

export const SwitchWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;
`;

export const HaySwitchWrapper = styled(SwitchWrapper)`
    //border-right: 1px solid #eaeced;
    //border-left: 1px solid #eaeced;
    //transition: all 200ms ease-in-out;
`;

export const SwitchCircle = styled('span')`
    display: block;
    width: 26px;
    height: 26px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);

    &.Switch-focusVisible {
        background-color: ${grey[500]};
        box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }

    &.Switch-checked {
        left: 22px;
        top: 3px;
        background-color: #fff;
    }

    &.Switch-hay-focusVisible {
        background-color: ${grey[500]};
        box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }

    &.Switch-hay-checked {
        left: 22px;
        top: 3px;
        background-color: #fff;
    }

    &.Switch-crop-checked {
        left: 22px;
        top: 3px;
        background-color: #fff;
    }
`;

export const SwitchController = styled('input')`
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
    transition: all 200ms ease;
`;

export const MainSwitchRoot = styled('span')`
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 53px;
    height: 32px;
    margin: 0 25px;
    background: ${grey[500]};
    border-radius: 40px;
    cursor: pointer;
    transition: all 200ms ease;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.12);

    &.Switch-checked {
        background: ${blue[500]};
        box-shadow: unset;
    }

    &.Switch-hay-checked {
        background: #5ea73c;
        box-shadow: unset;
    }

    &.Switch-crop-checked {
        background: #5ea73c;
        box-shadow: unset;
    }
`;

// new styles

export const SwitchFreeze = styled(Switch)`
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    display: inline-flex !important;
    justify-content: center !important;

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 13px;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(22px);
            color: #fff;

            & + .MuiSwitch-track {
                background-color: #52a1ff;
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }

        &.Mui-focusVisible .MuiSwitch-thumb {
            color: #52a1ff;
            border: 6px solid #fff;
        }

        &.Mui-disabled .MuiSwitch-thumb {
            color: gray;
            cursor: not-allowed;
        }

        &.Mui-disabled + .MuiSwitch-track {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 26px;
        height: 26px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    & .MuiSwitch-track {
        border-radius: 40px;
        background-color: #e9e9ea;
        opacity: 1;
        height: 32px;
        width: 54px;
    }
`;

export const SwitchHayVision = styled(Switch)`
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    display: inline-flex !important;
    justify-content: center !important;

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 13px;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(22px);
            color: #fff;

            & + .MuiSwitch-track {
                background-color: #5ea73c;
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }

        &.Mui-focusVisible .MuiSwitch-thumb {
            color: #5ea73c;
            border: 6px solid #fff;
        }

        &.Mui-disabled .MuiSwitch-thumb {
            color: white;
            cursor: not-allowed;
        }

        &.Mui-disabled + .MuiSwitch-track {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 26px;
        height: 26px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    & .MuiSwitch-track {
        border-radius: 40px;
        background-color: #e9e9ea;
        opacity: 1;
        height: 32px;
        width: 54px;
    }
`;

export const SwitchCropVision = styled(Switch)`
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    display: inline-flex !important;
    justify-content: center !important;

    & .MuiSwitch-switchBase {
        padding: 0;
        margin: 13px;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(22px);
            color: #fff;

            & + .MuiSwitch-track {
                background-color: #5ea73c;
                opacity: 1;
                border: 0;
            }

            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }

        &.Mui-focusVisible .MuiSwitch-thumb {
            color: #5ea73c;
            border: 6px solid #fff;
        }

        &.Mui-disabled .MuiSwitch-thumb {
            color: white;
            cursor: not-allowed;
        }

        &.Mui-disabled + .MuiSwitch-track {
            opacity: 0.3;
            cursor: not-allowed;
        }
    }

    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 26px;
        height: 26px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    & .MuiSwitch-track {
        border-radius: 40px;
        background-color: #e9e9ea;
        opacity: 1;
        height: 32px;
        width: 54px;
    }
`;
