import React from 'react';
import styled from 'styled-components';
import { useUserContext } from '../context/UserContextProvider';
import { ButtonContainer, ButtonFooter, CustomTextInput } from '../utils/GlobalStyles';

const Heading = styled.h3`
    padding: 1% 0;
`;

const SendLink = styled.button`
    background: #5ea73c;
    padding: 8px;
    color: white;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #5ea73c;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
`;

export default function ForgotPassword(props: {
    user_email: string;
    setEmail: any;
    hasAccount: boolean;
    setHasAccount: any;
    clearInputs: any;
}) {
    const { forgotPassword }: any = useUserContext();

    const { user_email, setEmail, hasAccount, setHasAccount, clearInputs } = props;

    return (
        <>
            <Heading>Forgot Password</Heading>
            <p>
                Enter your email and we’ll send an email with instructions to reset your
                password.
            </p>

            <CustomTextInput
                fullWidth
                label="Enter Email"
                type="email"
                value={user_email}
                onChange={(e) => {
                    console.log(e.target.value);
                    setEmail(e.target.value);
                }}
            />

            <ButtonContainer>
                <ButtonFooter
                    // className="btn-card-footer"
                    onClick={() => {
                        setHasAccount(!hasAccount);
                        clearInputs();
                    }}
                    style={{ width: '100%' }}
                >
                    Go Back
                </ButtonFooter>
                <SendLink
                    // className="login-btn"
                    onClick={() => {
                        console.log(user_email);
                        forgotPassword(user_email);
                    }}
                >
                    Send Link
                </SendLink>
            </ButtonContainer>
        </>
    );
}
