import axios from 'axios';
import { ALL_WORKERS } from '../../config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

async function get_all_workers() {
    return axios({
        method: 'GET',
        url: ALL_WORKERS,
    }).then((response) => {
        console.log(response.data);
        return response.data;
    });
}

export const update_worker = (data: {
    category: string | number;
    worker: { [x: string]: any };
}) => {
    return axios({
        method: 'PATCH',
        url: ALL_WORKERS + '/' + data.worker.id,
        data: {
            [data.category]: !data.worker[data.category],
        },
    }).then((res) => res.data);
};

const deleteWorker = (id: string) => {
    return axios({
        method: 'DELETE',
        url: ALL_WORKERS + '/' + id,
    }).then((res) => res.data);
};

const addWorker = (data: { email: string }) => {
    return axios({
        method: 'POST',
        url: ALL_WORKERS,
        data: {
            vision_category: 'hay_vision',
            icon: 'UserIcon',
            full_name: 'Bivav',
            email: data.email,
            hay_vision: true,
            garden_vision: true,
            crop_vision: true,
            freeze_vision: false,
        },
    }).then((res) => res.data);
};

export const useAddWorker = () => {
    const queryClient = useQueryClient();
    return useMutation(addWorker, {
        onSuccess: () => {
            toast.success('Worker added successfully');
            queryClient.invalidateQueries('allWorkers');
        },
    });
};

export const useWorkers = () => {
    return useQuery('allWorkers', get_all_workers);
};

export const useUpdateWorker = () => {
    const queryClient = useQueryClient();
    return {}
};

export const useDeleteWorker = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteWorker, {
        onSuccess: () => {
            console.log('Invalidating...');
            queryClient.invalidateQueries('allWorkers');
        },
    });
};
