import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/home_page/HomePage';
import ForgotPassword from './components/ForgotPassword';
import LandingPage from './screens/LandingPage';
import { createBrowserHistory } from 'history';
import React from 'react';
import FirstLogin from './screens/FirstLogin';
import { Slide, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AccountSettings from './screens/AccountSettings';
import Contacts from './screens/Contacts';
import Profile from './screens/Profile';
import Workers from './components/workers/Workers';
import HayVision from './components/hay_vision/HayVision';

import AddFieldHay from './components/hay_vision/AddField';
import HayField from './components/hay_vision/HayField';
import AddWorker from './components/workers/AddWorker';
import EditField from './components/hay_vision/EditField';

// import GardenVision from './components/garden_vision/GardenVision'
// import GardenField from "./components/garden_vision/GardenField";

function App() {
    return (
        <Router history={createBrowserHistory}>

            <ToastContainer
                position="top-right"
                autoClose={2500}
                transition={Slide}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />

            <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/first-login" element={<FirstLogin />} />
                <Route exact path="/home" element={<HomePage />} />
                <Route exact path="/workers" element={<Workers />} />
                <Route exact path="/workers/add-worker" element={<AddWorker />} />
                <Route exact path="/settings" element={<AccountSettings />} />
                <Route exact path="/contact" element={<Contacts />} />
                <Route exact path="/profile" element={<Profile />} />

                {/* Garden Vision Routes */}
                {/*<Route path="/garden-vision" element={<GardenVision />} />*/}
                {/*<Route*/}
                {/*    path="/garden-vision/field/:fieldName"*/}
                {/*    element={<GardenField />}*/}
                {/*/>*/}

                {/* Hay Vision Routes */}
                <Route path="/hay-vision" element={<HayVision />} />
                <Route path="/hay-vision/field/:fieldName" element={<HayField />} />
                <Route path="/hay-vision/add-field" element={<AddFieldHay />} />
                <Route path="/hay-vision/edit-field/:fieldId" element={<EditField />} />



                <Route path="*" element={<>No Match</>} />
            </Routes>
        </Router>
    );
}

export default App;
