import { createContext, useContext, useEffect, useState } from "react";
import {
	createUserWithEmailAndPassword,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut
} from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";

export const UserContext = createContext({})

export const useUserContext = () => {
    return useContext(UserContext)
}

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    useEffect(() => {
        setLoading(true)
        const unsubscribe = onAuthStateChanged(auth, (res) => {
            if (res) {
                setUser(res)
            } else {
                setUser(null)
            }
            setError('')
            setLoading(false)
        })
        setLoading(false)
        return unsubscribe
    }, [])

    const createUser = (email, password) => {
        setLoading(true)
        return createUserWithEmailAndPassword(auth, email, password)
    }

    const loginUser = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logoutUser = () => {
        signOut(auth)
    }

    const forgotPassword = (email) => {
        return sendPasswordResetEmail(auth, email, {
            url: 'http://localhost:3000',
        })
    }

    const contextValue = {
        user,
        loading,
        error,
        loginUser,
        createUser,
        logoutUser,
        forgotPassword,
        emailError,
        passwordError,
        setEmailError,
        setPasswordError,
    }
    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}
