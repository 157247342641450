import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter } from '../../utils/GlobalStyles';
import { useNavigate } from 'react-router-dom';

interface Props {
    handleClose: () => void;
}

const LogoutAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const LogoutText = styled.p`
    margin: 1em 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;

const LogoutAccountBtn = styled.div`
    background: #ed4343;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`;

const LogoutAccount = (props: Props) => {
    const [deleteText, setDeleteText] = useState('');

    let navigate = useNavigate();

    return (
        <LogoutAccountWrapper>
            <Heading>Log Out?</Heading>
            <LogoutText>
                Are you sure you want to Log Out of this account on this device?
            </LogoutText>
            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Cancel</ButtonFooter>
                <LogoutAccountBtn onClick={() => navigate('/')}>
                    Log Out
                </LogoutAccountBtn>
            </ButtonCont>
        </LogoutAccountWrapper>
    );
};

export default LogoutAccount;
