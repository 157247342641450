import React from "react";

import styled from "styled-components";
import HeaderLeft from "../../components/HeaderLeft";
import SideNav from "../../components/sidenav/SideNav";
import HeaderRight from "../../components/HeaderRight";
import { ButtonContainer, ButtonFooter, CustomTextInput } from "../../utils/GlobalStyles";
import { useNavigate } from "react-router-dom";
import { useAddWorker } from "../hooks/useWorkers";

const AddWorkerWrapper = styled.div`
  background-color: transparent;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  height: 100vh;
  padding: 0;
  grid-template-columns: 240px 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
  grid-area: acc-header;
  background-color: white;
  padding: 0 4em;
  height: 18vh;
  overflow-x: hidden;
  border-bottom: 1px solid #e9eced;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 1fr;
  grid-template-areas: 'header-left-content . header-right-content';
`;

const AddWorkerContent = styled.div`
  grid-area: acc-content;
`;

const AddWorkerContentBox = styled.div`
  width: 300px;
  margin: 3.5em 4em;
  display: grid;
`;

const ChangeDetailsBtn = styled.button`
  background: #5ea73c;
  padding: 8px;
  border: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  &:hover {
    transition: 0.3s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
  }
`;

export default function AddWorker() {
    const navigate = useNavigate();

    const [workerEmail, setWorkerEmail] = React.useState("");

    const addWorker = useAddWorker();

    return (
        <AddWorkerWrapper>
            <SideNav />
            <AddWorkerContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={"Smith Farms"}
                        subTitle={"35 Workers & 120 Fields"}
                    />
                    <HeaderRight />
                </HeaderWrapper>

                <form name={"add_new_worker"}>
                    <AddWorkerContentBox>
                        <CustomTextInput
                            label="Enter worker email..."
                            type="email"
                            value={workerEmail}
                            required
                            onChange={(e) => setWorkerEmail(e.target.value)}
                        />

                        <ButtonContainer>
                            <ButtonFooter
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Go Back
                            </ButtonFooter>
                            <ChangeDetailsBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    let form: any = document;

                                    if (form["add_new_worker"].reportValidity()) {
                                        addWorker.mutate({
                                            email: workerEmail
                                        });
                                    }
                                }}
                            >
                                Send Invite
                            </ChangeDetailsBtn>
                        </ButtonContainer>
                    </AddWorkerContentBox>
                </form>
            </AddWorkerContent>
        </AddWorkerWrapper>
    );
}
