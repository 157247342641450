import React, { useEffect, useState } from 'react';
import '../modal/modal.css';
import styled from 'styled-components';
import { CustomSearchInput } from '../../utils/GlobalStyles';
import { InputAdornment } from '@mui/material';

import { RiSearchLine } from 'react-icons/ri';
import { update_worker, useUpdateWorker } from "../hooks/useWorkers";
import HayVisionSwitch from '../workers/HayVisionSwitch';
import { dummy } from '../../screens/Profile';
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const ModalBodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content clamp(50%, 500px, 74%);
`;

const ModalHeader = styled.div`
    padding: 10px;
    position: sticky;

    .title_wrapper {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;
const SubText = styled.p`
    margin: 0;
`;

const ImageWrapper = styled.div`
    width: max-content;
    position: relative;
    display: grid;
`;

const ImgContent = styled.div`
    width: max-content;
    padding: 10px;

    & > img {
        border-radius: 50px;
    }
`;

const ProfileTitleWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
`;

const ProfileTitle = styled.h4`
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #4b4b4b;
`;

const ProfileSubtitle = styled.p`
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    color: #7d7d7d;
    padding: 0;
    margin: 0;
    grid-area: profile_subname;

    &:hover {
        color: #4b4b4b;
    }
`;

const ModalBody = styled.div`
    overflow-y: auto;
    height: auto;
`;

const ModalBodyItem = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    > div {
        width: 100%;
        display: inline-flex;
    }
`;

export const WorkersModal = (props) => {
    const { title, subText, workers } = props;
    const [searchInput, setSearchInput] = useState('');

    const filteredWorkers = (worker_data) => {
        return worker_data.filter((eachObject) => {
            return eachObject.full_name.toLowerCase().includes(searchInput.toLowerCase());
        });
    };

    const [allWorkers, setAllWorkers] = useState([]);

    useEffect(() => {
        setAllWorkers(
            workers.filter((eachWorker) =>
                eachWorker['vision_category'].includes('hay_vision')
            )
        );
    }, [workers]);
    const queryClient = useQueryClient();

    const updateWorker = useMutation(update_worker, {
        onSuccess: () => {
            console.log('Invalidating...');
            queryClient.invalidateQueries('allWorkers');
        },
        onError: (err) => {
            toast.error(err.message);
            setTimeout(() => {
                setAllWorkers(
                  workers.filter((eachWorker) =>
                    eachWorker['vision_category'].includes('hay_vision')
                  )
                );
            }, 2000);
        },
    });

    const handleUpdate = async (worker, category) => {
        await setAllWorkers((prevState) =>
            prevState.map((w) => {
                if (w.id === worker.id) {
                    return {
                        ...w,
                        [category]: !worker[category],
                    };
                } else {
                    return w;
                }
            })
        );
        let data = { worker, category };
        updateWorker.mutate(data);
    };

    return (
        <ModalBodyWrapper>
            <ModalHeader>
                <div className={'title_wrapper'}>
                    <Heading>{title}</Heading>
                    <CustomSearchInput
                        placeholder={'Search worker...'}
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RiSearchLine />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <SubText>{subText}</SubText>
            </ModalHeader>
            <ModalBody>
                {filteredWorkers(allWorkers).length !== 0 ? (
                    filteredWorkers(allWorkers).map((worker, index) => (
                        <ModalBodyItem key={index}>
                            <div>
                                <ImageWrapper>
                                    <ImgContent>
                                        <img width={45} src={dummy} alt="logo" />
                                    </ImgContent>
                                </ImageWrapper>

                                <ProfileTitleWrapper>
                                    <div>
                                        <ProfileTitle>{worker.full_name}</ProfileTitle>
                                        <ProfileSubtitle>{worker.email}</ProfileSubtitle>
                                    </div>
                                </ProfileTitleWrapper>
                            </div>

                            <div
                                style={{
                                    display: 'grid',
                                    justifyContent: 'right',
                                }}
                            >
                                <HayVisionSwitch
                                    onChange={() => handleUpdate(worker, 'hay_vision')}
                                    disabled={worker['freeze_vision'] ? 1 : 0}
                                    checked={worker.hay_vision}
                                />
                            </div>
                        </ModalBodyItem>
                    ))
                ) : (
                    <p>No Workers</p>
                )}
            </ModalBody>
        </ModalBodyWrapper>
    );
};
