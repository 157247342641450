import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import FIRST_LOGIN_BG from '../static/images/first-login-bg.png'
import FLOWER_POTS from '../static/images/flower-pots.svg'
import ECT_VISION from '../static/images/logo.svg'
import { toast } from 'react-toastify'
import {
    ButtonContainer,
    ButtonFooter,
    CustomTextInput,
} from '../utils/GlobalStyles'
import axios from 'axios'
import { BASE_URL } from '../config'

const AfterLoginWrapper = styled.div`
    background-image: url(${FIRST_LOGIN_BG});
    overflow-x: hidden;
    height: 100vh;
    display: grid;
    padding: 0 5%;
    background-repeat: no-repeat;
    background-size: cover;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        'top-left-first top-left-first . . .'
        '. . mid-content-first . bottom-content-first'
        '. . . . bottom-content-first';
`

const MidContent = styled.div`
    grid-area: mid-content-first;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    transition: 0.3s;
    display: inline-block;
    background-color: white;
    width: 400px;

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.103);
    }
`

const FlowerPotImg = styled.div`
    grid-area: bottom-content-first;
    display: grid;
    justify-content: end;
    align-content: end;
`

const NavLogoContent = styled.div`
    grid-area: top-left-first;
    display: grid;
    width: auto;
    padding: 2em 0;
`

const WelcomeWrapper = styled.div`
    padding: 5% 8%;
`

const WelcomeHeading = styled.h1`
    font-weight: 600;
    font-size: 30px;
`

const WelcomePara = styled.p`
    font-size: 12px;
`

const ChangePasswordBtn = styled.button`
    background: #5ea73c;
    padding: 12px 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 12px 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`

export default function FirstLogin() {
    let history = useNavigate()
    const [user_data, setUserData] = useState(null)

    const [isClicked, setIsClicked] = useState(false)

    useEffect(() => {
        axios({
            method: 'GET',
            url: BASE_URL + '/login',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        })
            .then((res) => {
                setUserData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const Welcome = (props) => {
        const [password, setPassword] = useState('')
        const [re_password, setRePassword] = useState('')

        function changePassword() {
            if (
                password === re_password &&
                password !== '' &&
                re_password !== ''
            ) {
                axios({
                    method: 'POST',
                    url: BASE_URL + '/change-password',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'x-mock-match-request-body': true,
                    },
                    data: {
                        email: user_data['email'],
                        password: password,
                    },
                })
                    .then((res) => {
                        if (res.status === 201) {
                            console.log(res.data.message)
                            toast.success(res.data.message, {
                                hideProgressBar: true,
                            })
                            setIsClicked(true)
                        } else {
                            console.log('Not 201', res)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toast.error("Password didn't match!", {
                    hideProgressBar: true,
                })
            }
        }

        return (
            <WelcomeWrapper>
                {user_data !== null ? (
                    <>
                        <WelcomeHeading>
                            Hi {user_data['full_name'].split(' ')[0]},
                        </WelcomeHeading>
                        <WelcomePara>
                            We would recommend you to change your default
                            password to a custom one :)
                        </WelcomePara>

                        <CustomTextInput
                            fullWidth
                            label="Enter password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <CustomTextInput
                            fullWidth
                            label="Re-Enter password"
                            type="password"
                            value={re_password}
                            onChange={(e) => setRePassword(e.target.value)}
                        />

                        <ButtonContainer>
                            <ButtonFooter
                                onClick={() => {
                                    toast.info('No change in password', {
                                        hideProgressBar: true,
                                    })
                                    setIsClicked(true)
                                }}
                            >
                                Skip for now
                            </ButtonFooter>
                            <ChangePasswordBtn onClick={() => changePassword()}>
                                Change
                            </ChangePasswordBtn>
                        </ButtonContainer>
                    </>
                ) : (
                    <>Loading...</>
                )}
            </WelcomeWrapper>
        )
    }

    const ProfileDetails = (props) => {
        const { user_data } = props

        const [full_name, setFullName] = useState(user_data['full_name'])
        const [account_name, setAccountName] = useState(
            user_data['account_name']
        )

        function changeDetails() {
            history('/home', { push: true })

            // axios({
            //     method: 'POST',
            //     url: BASE_URL + '/change-password',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         'x-mock-match-request-body': true,
            //     },
            //     data: {
            //         email: user_data['email'],
            //         full_name: full_name,
            //         account_name: account_name,
            //     },
            // })
            //     .then((res) => {
            //         if (res.status === 201) {
            //             console.log(res.data.message)
            //             toast.success(res.data.message, {
            //                 hideProgressBar: true,
            //             })
            //
            //             history('/home', { push: true })
            //         } else {
            //             console.log('Not 201', res)
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(err)
            //     })
        }

        return (
            <WelcomeWrapper>
                <WelcomeHeading>Your Details,</WelcomeHeading>
                <WelcomePara>
                    You can edit these details in the future as well.
                </WelcomePara>

                <CustomTextInput
                    fullWidth
                    label="Your Name"
                    type="text"
                    value={full_name}
                    onChange={(e) => setFullName(e.target.value)}
                />

                <CustomTextInput
                    fullWidth
                    label="Account Name"
                    type="text"
                    value={account_name}
                    onChange={(e) => setAccountName(e.target.value)}
                />

                <CustomTextInput
                    fullWidth
                    label="Email"
                    type="email"
                    disabled
                    value={user_data['email']}
                />

                <ButtonContainer>
                    <ButtonFooter
                        onClick={() => {
                            setIsClicked(false)
                        }}
                    >
                        Go Back
                    </ButtonFooter>
                    <ChangeDetailsBtn onClick={() => changeDetails()}>
                        Done
                    </ChangeDetailsBtn>
                </ButtonContainer>
            </WelcomeWrapper>
        )
    }

    return (
        <AfterLoginWrapper>
            <NavLogoContent>
                <img src={ECT_VISION} alt="logo" />
            </NavLogoContent>

            <MidContent>
                {isClicked === false ? (
                    <Welcome />
                ) : (
                    <ProfileDetails user_data={user_data} />
                )}
            </MidContent>

            <FlowerPotImg>
                <img width={200} src={FLOWER_POTS} alt={''} />
            </FlowerPotImg>
        </AfterLoginWrapper>
    )
}
