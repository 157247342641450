import React, { useState } from 'react';
import styled from 'styled-components';
import HeaderLeft from '../HeaderLeft';
import SideNav from '../sidenav/SideNav';
import { ButtonContainer, ButtonFooter, CustomTextInput } from '../../utils/GlobalStyles';
import { AddFieldModal } from './AddFieldModal';
import { useNavigate } from 'react-router-dom';
import { useAddHayFields } from '../hooks/useHayVision';
import { toast } from 'react-toastify';
import ModalComponent from '../modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';

const FieldContentWrapper = styled.div`
    background-color: transparent;
    width: 100%;
    overflow-x: hidden;
    display: grid;
    height: 100vh;
    padding: 0;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'side-nav acc-content';
`;

const HeaderWrapper = styled.div`
    grid-area: acc-header;
    background-color: white;
    padding: 0 4em;
    height: 18vh;
    overflow-x: hidden;
    border-bottom: 1px solid #e9eced;
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 1fr;
    grid-template-areas: 'header-left-content . header-right-content';
`;

const FieldContent = styled.div`
    grid-area: acc-content;
`;

const AddFieldWrapper = styled.div`
    width: 340px;
    margin: 2em 4em;
    display: grid;
    justify-content: center;

    .form {
        width: 340px;
    }

    ${CustomTextInput} {
        width: 330px;
    }
`;

const ChangeDetailsBtn = styled.button`
    background: #5ea73c;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const LocationSelector = styled.div`
    font-size: 14px;
    width: 330px;
    height: 185px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    color: #98a3aa;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        text-decoration: underline;
    }
`;

export default function AddField() {
    let navigate = useNavigate();

    // const [showLocationModal, setShowLocationModal] = useState(false)

    const [fieldData, setFieldData] = useState({
        title: 'HAY VISION',
        field_name: '',
        field_size: '',
        hay_type: '',
        location: {
            address: '',
            lat: 39.205907,
            lng: -94.290529,
        },
        edit_icon: 'EditIcon',
        location_image: '',
    });

    const handleChangeFieldInfo = (event) => {
        setFieldData({ ...fieldData, [event.target.name]: event.target.value });
    };

    const addHayFields = useAddHayFields();

    function addNewField(fieldData) {
        addHayFields.mutate(fieldData);
    }

    const [modalAddField, setModalAddField] = useState(false);
    const closeAddField = () => setModalAddField(false);
    const openAddField = () => setModalAddField(true);

    return (
        <FieldContentWrapper>
            <SideNav />
            <FieldContent>
                <HeaderWrapper>
                    <HeaderLeft
                        title={'Hay Vision'}
                        subTitle={'Add Field to Hay Vision'}
                    />
                </HeaderWrapper>

                <AddFieldWrapper>
                    <form name={'create_new_field'} className={'form'}>
                        <CustomTextInput
                            label="Enter field name..."
                            type="text"
                            name={'field_name'}
                            value={fieldData.field_name}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />
                        <CustomTextInput
                            label="Enter field area (in acres)"
                            type="text"
                            name={'field_size'}
                            value={fieldData.field_size}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />

                        <CustomTextInput
                            label="Enter hay type"
                            type="text"
                            name={'hay_type'}
                            value={fieldData.hay_type}
                            onChange={(e) => handleChangeFieldInfo(e)}
                            required
                        />

                        <div>
                            <p
                                style={{
                                    fontSize: 14,
                                    color: '#98A3AA',
                                    marginBottom: '5px',
                                }}
                            >
                                Field Location
                            </p>
                            {!!fieldData.location_image ? (
                                <p
                                    style={{
                                        fontSize: 12,
                                        color: '#98A3AA',
                                        margin: '0 0 5px 0',
                                    }}
                                >
                                    (Lat:{' '}
                                    {fieldData.location.lat.toString().substring(0, 8)}{' '}
                                    Lng:{' '}
                                    {fieldData.location.lng.toString().substring(0, 8)})
                                </p>
                            ) : null}

                            <LocationSelector
                                onClick={() => {
                                    modalAddField ? closeAddField() : openAddField();
                                }}
                            >
                                {!!fieldData.location_image ? (
                                    <img
                                        src={fieldData.location_image
                                            .replace('size=200x115', 'size=328x182')
                                            .replace('zoom=12', 'zoom=14')
                                            .replace('color:0xCBD1D4', 'color:0xde1d3a')}
                                        alt={''}
                                    />
                                ) : (
                                    'Click to select from map'
                                )}
                            </LocationSelector>

                            <AnimatePresence
                                // Disable any initial animation on children for first render
                                initial={false}
                                exitBeforeEnter={true}
                            >
                                {modalAddField && (
                                    <ModalComponent
                                        open={modalAddField}
                                        handleClose={closeAddField}
                                        title={''}
                                        minWidth={'45%'}
                                        width={'600px'}
                                    >
                                        <AddFieldModal
                                            title={'CHOOSE FIELD LOCATION'}
                                            onClose={closeAddField}
                                            location={fieldData.location}
                                            fieldData={fieldData}
                                            setFieldData={setFieldData}
                                        />
                                    </ModalComponent>
                                )}
                            </AnimatePresence>
                        </div>

                        <ButtonContainer>
                            <ButtonFooter
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(-1);
                                }}
                            >
                                Go Back
                            </ButtonFooter>
                            <ChangeDetailsBtn
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        document.forms[
                                            'create_new_field'
                                        ].reportValidity() &&
                                        !!fieldData.location_image
                                    ) {
                                        addNewField(fieldData);
                                    } else {
                                        toast.error('Please fill the details.');
                                    }
                                }}
                            >
                                Add Field
                            </ChangeDetailsBtn>
                        </ButtonContainer>
                    </form>
                </AddFieldWrapper>
            </FieldContent>
        </FieldContentWrapper>
    );
}
