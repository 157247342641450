import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './static/css/styles.css';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './states/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { UserContextProvider } from './context/UserContextProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import LandingPage from "./screens/LandingPage";

const queryClient = new QueryClient()

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <UserContextProvider>
                <Suspense fallback={<div>Loading</div>}>
                    {/*<App />*/}
                    <LandingPage/>
                </Suspense>
                <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"}/>
            </UserContextProvider>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
serviceWorker.unregister()
