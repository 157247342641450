import * as actions from "./actionTypes"

function LoadingReducer(state = false, action) {
	switch (action.type) {
		case actions.IS_LOADING:
			return action.payload.value
		default:
			return state
	}
}

export {LoadingReducer}