import styled from "styled-components";
import HAY_VISION from "../../static/images/hay-vision.png";

export const DashboardContent = styled.div`
    height: 100vh;
    display: grid;
    overflow-y: hidden;
    padding: 0;
    grid-template-columns: 240px 2fr 400px;
    grid-template-rows: auto;
    grid-template-areas:
        'side-nav home-content map-content'
        'side-nav home-content map-content'
        'side-nav home-content map-content';
`

export const MainContent = styled.div`
    grid-area: home-content;
    background: #ffffff;
    padding: 0 4em;
`

export const Content = styled.div`
    width: 100%;
    display: grid;
    margin-top: 2em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 4em;
    grid-row-gap: 2em;
`

export const ARROW = styled.span`
    color: transparent;
    margin-right: 14px;
    transition: ease-in-out 0.2s;
`

export const Heading = styled.h1`
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 0;
`

export const VisionImg = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.3) 0 10px 30px 0;
`

VisionImg.defaultProps = {
    src: HAY_VISION,
}

export const ComingSoon = styled.img`
    position: absolute;
    top: 50px;
    width: 100%;
    height: auto;
    z-index: 1;
    cursor: not-allowed;
`

export const ItemWrapper = styled.div`
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: #9ba298;
    display: grid;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
    border-radius: 14px;
    position: relative;

    &:hover ${Heading} > ${ARROW} {
        color: green;
        transform: translateX(12px);
    }

    &:hover > ${VisionImg} {
        -ms-transform: scale(1.03); /* IE 9 */
        -webkit-transform: scale(1.03); /* Safari 3-8 */
        transform: scale(1.03);
    }

    &:hover > ${ComingSoon} {
    }

    &:hover {
        color: #282c34;
        box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0,
            rgba(27, 31, 35, 0.15) 0 0 0 1px;
    }
`

export const ComingSoonWrapper = styled(ItemWrapper)`
    cursor: not-allowed;
    color: #cdd0cb;

    &:hover {
        color: #cdd0cb;
        box-shadow: unset;
    }

    &:hover ${Heading} > ${ARROW} {
        display: none;
    }

    &:hover > ${VisionImg} {
        -ms-transform: scale(1); /* IE 9 */
        -webkit-transform: scale(1); /* Safari 3-8 */
        transform: scale(1);
        cursor: not-allowed;
    }
`

export const EventWrapper = styled.div`
    grid-area: map-content;
    background: #efefef;
    border-left: 1px solid #e5e5e5;
`

export const VisionHeading = styled(Heading)`
    font-size: 24px;
    margin: 0;
    padding: 0.5em 0.5em;
    font-weight: 600;
    display: inline-flex;
    justify-content: space-between;
`

export const SubHeading = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    color: #999;
`

export const MapSection = styled.div``

export const AllEventSection = styled.section`
    height: 48vh;
    background: white;
    overflow-y: scroll;
`

export const EventHeader = styled.div`
    display: inline-flex;
    width: 100%;
    height: 6vh;
    background: white;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 1px solid #e5e5e5;
`

export const EventHeading = styled(Heading)`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Oswald', sans-serif;
    color: #9ca499;
    margin: 0;
    padding: 1em;
`

export const EventSubHeading = styled(SubHeading)`
    font-size: 12px;
    margin: 0;
    padding: 1em;
    color: #9ca499;
`

export const ResetButton = styled.button`
  border: none;
  text-decoration: underline;
  background: transparent;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  padding: 4px 8px;
  margin-right: 15px;

`
