import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonContainer, ButtonFooter, CustomTextInput } from '../../utils/GlobalStyles';

interface Props {
    handleClose: () => void;
}

const DeleteAccountWrapper = styled.div`
    padding: 1em;
    color: #414149;
`;

const Heading = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: Oswald, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #9ba298;
`;

const DeleteText = styled.p`
    margin: 1em 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`;

const DeleteAccountBtn = styled.div`
    background: #ed4343;
    padding: 8px;
    border: none;
    color: white;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    //box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    &:hover {
        transition: 0.3s;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
    }
`;

const ButtonCont = styled(ButtonContainer)`
    padding: 1rem 0 0 0;
`

const DeleteAccount = (props: Props) => {
    const [deleteText, setDeleteText] = useState('');

    return (
        <DeleteAccountWrapper>
            <Heading>Delete Account?</Heading>
            <DeleteText>
                You will have to contact our ECT Team within 30 days after you delete this
                account to recover it back! If you want to continue deleting this account,
                type in ‘DELETE’ in the field below.
            </DeleteText>
            <CustomTextInput
                fullWidth
                label="Type ‘DELETE’ here to continue"
                type="text"
                value={deleteText}
                onChange={(e) => setDeleteText(e.target.value.toUpperCase())}
            />
            <ButtonCont>
                <ButtonFooter onClick={() => props.handleClose()}>Close</ButtonFooter>
                <DeleteAccountBtn onClick={() => props.handleClose()}>
                    Delete
                </DeleteAccountBtn>
            </ButtonCont>
        </DeleteAccountWrapper>
    );
};

export default DeleteAccount;
