import React from 'react';
import styled from 'styled-components';

interface ProgressProps {
    allEventsCount: number;
    completeCount: number;
}

const EventsCalc = styled.div<ProgressProps>`
    background: #f4f4f4;
    width: 100%;
    transition: 0.2s;

    & > div {
        background: #60a83e;
        // Calculating the % for each event marked as complete from All Events
        width: ${(props) => (props['completeCount'] / props['allEventsCount']) * 100}%;
        height: 10px;
        transition: 0.4s;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
`;

export default function EventsProgressBar(props: ProgressProps) {
    const { allEventsCount, completeCount } = props;

    return (
        <EventsCalc allEventsCount={allEventsCount} completeCount={completeCount}>
            {/* Below div is where the progressbar is increasing and decreasing */}
            <div />
        </EventsCalc>
    );
}
