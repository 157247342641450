import React, { useState } from 'react';
import Footer from '../components/Footer';
import LoginPage from '../components/LoginPage';
import NavBar from '../components/NavBar';

import { useUserContext } from '../context/UserContextProvider';
import ForgotPassword from '../components/ForgotPassword';
import ENERGY_CURVE from '../static/images/energy-curve.svg';
import LOGIN_BACKGROUND from '../static/coming_soon.png';
import PLANT from '../static/plant.png';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CustomCard, CustomCardContainer } from '../utils/GlobalStyles';

const ContentWrapper = styled.div`
    background-color: transparent;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        '. . . . .'
        'left_login left_login . right_login right_login'
        'left_login left_login . right_login right_login'
        'left_login left_login . right_login right_login';
`;

const LandingPageContent = styled.div`
    background-image: url(${LOGIN_BACKGROUND});
    /* Full height */
    height: 100vh;
    padding: 0 5%;
    display: grid;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
`;

const LeftLoginContainer = styled.div`
    grid-area: left_login;
    display: grid;
    align-items: center;

    img {
        width: 500px;
    }
`;

const RightLoginContainer = styled.div`
    grid-area: right_login;
    display: grid;
    justify-content: center;
`;

const LearnMoreBtn = styled.button`
    background-color: black;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    //width: 30%;
    padding: 1% 4%;
    height: min-content;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.3px;

    & > span {
        position: relative;
        bottom: 4px;
        left: 8px;
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        background-color: transparent;
        color: #171b2c;
        border: 1px solid #171b2c;
    }

    &:hover > span > svg > line {
        stroke: black;
        transition: all 0.2s ease-in-out;
    }
`;

const LandingPageWrapper = styled.div`
    background-image: url(${LOGIN_BACKGROUND});
    background-color: #f7fbf6;
    /* Full height */
    height: 100vh;
    padding: 0 5%;
    display: grid;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
`;

const LearnMore = styled.div`
    position: absolute;
    bottom: 18em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
`;

const PLANT_IMG = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 240px;
`

export default function LandingPage() {
    return (
        <LandingPageWrapper>
            <NavBar />
            <LearnMore>
                <LearnMoreBtn
                    onClick={() => {
                        window.location.href =
                            'https://www.energycurve.com/vision-platform';
                    }}
                >
                    Learn More{' '}
                    <span>
                        <svg
                            width="42"
                            height="1"
                            viewBox="0 0 42 1"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line x1="0.5" y1="0.5" x2="41.5" y2="0.5" stroke="white" />
                        </svg>
                    </span>
                </LearnMoreBtn>
            </LearnMore>
            <PLANT_IMG src={PLANT} />
            <Footer />
        </LandingPageWrapper>
    );
}
