import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as INSTAGRAM_ICON } from '../static/icons/instagram.svg';
import { ReactComponent as TWITTER_ICON } from '../static/icons/twitter.svg';
import { ReactComponent as LINKEDIN_ICON } from '../static/icons/linkedin.svg';
import { ReactComponent as FACEBOOK_ICON } from '../static/icons/facebook.svg';
import { ReactComponent as TIKTOK_ICON } from '../static/icons/tiktok.svg';
import ModalComponent from './modal/ModalComponent';
import { AnimatePresence } from 'framer-motion';
import DeleteAccount from './account/DeleteAccount';
import LogoutAccount from "./account/LogoutAccount";
import { useNavigate } from "react-router-dom";

const HeaderWrapper = styled.div`
    grid-area: header-right-content;
    display: grid;
    align-content: center;
    justify-content: end;
    align-items: center;
`;

const SubHeading = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #ed4343;
    cursor: pointer;

    & > span > svg {
        padding: 4px;
    }
`;

const AddWorkerTitle = styled(SubHeading)`
    border: none;
    background-color: #fff;
    width: max-content;
    color: #5ea73c;
    padding: 1em;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    transition: all ease-in-out 0.2s;
    display: inline-flex;
    justify-content: space-between;
    gap: 6px;

    &:hover {
        background-color: rgba(94, 167, 60, 0.07);
    }
`;

const DeleteAccountBtn = styled(AddWorkerTitle)`
    color: #ed4343;
`;

const LogOutBtn = styled(AddWorkerTitle)`
    color: #ed4343;
    padding: 0.8em 1.2em;
`;

const ARROW = styled.span`
    color: #ed4343;
    transition: ease-in-out 0.2s;
`;

interface HeaderRightProps {
    text?: string;
}

export default function HeaderRight(props: HeaderRightProps) {

    const [modalDeleteWorker, setModalDeleteWorker] = useState(false);
    const closeDeleteModalWorker = () => setModalDeleteWorker(false);
    const openDeleteModalWorker = () => setModalDeleteWorker(true);

    const [modalLogOut, setModalLogOut] = useState(false);
    const closeModalLogOut = () => setModalLogOut(false);
    const openModalLogOut = () => setModalLogOut(true);

    const navigate = useNavigate()

    return (
        <HeaderWrapper>
            <SubHeading>
                {props.text === 'social' ? (
                    <span>
                        <INSTAGRAM_ICON />
                        <TWITTER_ICON />
                        <LINKEDIN_ICON />
                        <FACEBOOK_ICON />
                        <TIKTOK_ICON />
                    </span>
                ) : props.text === 'add_worker' ? (
                    <AddWorkerTitle onClick={() => navigate("/workers/add-worker")}>
                        Add Worker <ARROW>&#10132;</ARROW>
                    </AddWorkerTitle>
                ) : props.text === 'delete' ? (
                    <DeleteAccountBtn
                        onClick={() => {
                            modalDeleteWorker
                                ? closeDeleteModalWorker()
                                : openDeleteModalWorker();
                        }}
                    >
                        Delete Account <ARROW>&#10132;</ARROW>
                        <AnimatePresence
                            // Disable any initial animation on children for first render
                            initial={false}
                            exitBeforeEnter={true}
                        >
                            {modalDeleteWorker && (
                                <ModalComponent
                                    key={'modal-worker'}
                                    open={openDeleteModalWorker}
                                    handleClose={closeDeleteModalWorker}
                                    minWidth={'15%'}
                                    width={'350px'}
                                    minHeight={'200px'}
                                >
                                    <DeleteAccount handleClose={closeDeleteModalWorker} />
                                </ModalComponent>
                            )}
                        </AnimatePresence>
                    </DeleteAccountBtn>
                ) : props.text === 'logout' ? (
                    <LogOutBtn
                        onClick={() =>
                            modalLogOut ? closeModalLogOut() : openModalLogOut()
                        }
                    >
                        Log Out <ARROW>&#10132;</ARROW>
                        <AnimatePresence
                            // Disable any initial animation on children for first render
                            initial={false}
                            exitBeforeEnter={true}
                        >
                            {modalLogOut && (
                                <ModalComponent
                                    key={'modal-worker'}
                                    open={openModalLogOut}
                                    handleClose={closeModalLogOut}
                                    minWidth={'15%'}
                                    width={'350px'}
                                    minHeight={'200px'}
                                >
                                    <LogoutAccount handleClose={closeModalLogOut} />
                                </ModalComponent>
                            )}
                        </AnimatePresence>
                    </LogOutBtn>
                ) : (
                    <></>
                )}
            </SubHeading>
        </HeaderWrapper>
    );
}
