import React from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    grid-area: header-left-content;
`;

const Header = styled.div`
    display: grid;
    align-content: center;
`;

const Heading = styled.h1`
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 0;
`;
const SubHeading = styled.p`
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    color: #999;
`;

interface HeaderLeftProps {
    title: string;
    subTitle: string;
}

export default function HeaderLeft(props: HeaderLeftProps) {
    return (
        <HeaderWrapper>
            <Header>
                <Heading>{props.title}</Heading>
                <SubHeading>{props.subTitle}</SubHeading>
            </Header>
        </HeaderWrapper>
    );
}
