import React from 'react';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as LOCATION_PIC } from '../static/icons/location-pin.svg';
import { GOOGLE_MAP_API } from '../config';
import styled from 'styled-components';

const LocationText = styled.p`
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    width: 200px;
    color: #008f00;
`;

const LocationMarker = styled.div`
    display: grid;
    justify-content: center;
    position: absolute;
`;

const LocationPin = ({ text, location, setMapCenter, setZoomLevel, zoomLevel }) => (
    <LocationMarker
        onClick={() => {
            setZoomLevel(15);
            setMapCenter(location);
        }}
    >
        <LOCATION_PIC />
        {zoomLevel === 15 || zoomLevel === 13 ? (
            <LocationText>{text}</LocationText>
        ) : null}
    </LocationMarker>
);

const Map = ({
    location,
    mapCenter,
    setMapCenter,
    zoomLevel,
    setZoomLevel,
    mapLocations,
}) => {
    // Recenter the map after reset
    // TODO: Connect to reset button
    function getBounds(map, maps) {
        // Return map bounds based on list of places
        const bounds = new maps.LatLngBounds();

        mapLocations.forEach((place) => {
            bounds.extend(new maps.LatLng(place.location.lat, place.location.lng));
        });
        console.log(bounds);
        map.fitBounds(bounds);
        return bounds;
    }

    // Important! Always set the container height explicitly
    return (
        <div style={{ height: '45vh', width: 'auto' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
                zoom={zoomLevel}
                center={mapCenter}
                // onGoogleApiLoaded={({ map, maps }) => getBounds(map, maps)}
            >
                {mapLocations.map((value, index) => (
                    <LocationPin
                        key={index}
                        lat={value.location.lat}
                        lng={value.location.lng}
                        text={value.location.address}
                        setZoomLevel={setZoomLevel}
                        zoomLevel={zoomLevel}
                        setMapCenter={setMapCenter}
                        location={value.location}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};

export default Map;
